@font-face {
  font-family: Roboto;
  src: local(Roboto Light), local(Roboto-Light), url("Roboto-Light.d07a8413.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: local(Roboto Regular), local(Roboto-Regular), url("Roboto-Regular.57844c26.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: local(Inter Regular), local(Inter-Regular), url("Inter-Regular.c3474174.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: local(Inter Medium), local(Inter-Medium), url("Inter-Medium.275a3474.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: local(Inter SemiBold), local(Inter-SemiBold), url("Inter-SemiBold.0cbc243f.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: local(Inter Bold), local(Inter-Bold), url("Inter-Bold.ff33b6b2.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Roboto, Inter, system-ui, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.hidden {
  display: none;
}

.min-h-screen {
  min-height: 100vh;
}

.text-fluid-5xl {
  font-size: clamp(3rem, 2.5rem + 4vw, 4.5rem);
  line-height: 1;
}

.text-fluid-base {
  font-size: clamp(1rem, .9375rem + .5vw, 1.125rem);
  line-height: 1.6;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.uppercase {
  text-transform: uppercase;
}

.leading-relaxed {
  line-height: 1.5;
}

.text-brand-email {
  --tw-text-opacity: 1;
  color: rgb(255 0 0 / var(--tw-text-opacity, 1));
}

.text-brand-phone {
  --tw-text-opacity: 1;
  color: rgb(0 128 0 / var(--tw-text-opacity, 1));
}

body {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.main-section {
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@media (width >= 375px) {
  .main-section {
    align-items: stretch;
  }
}

@media (width >= 640px) {
  .main-section {
    align-items: center;
  }
}

@media (width >= 1024px) {
  .main-section {
    flex-direction: row-reverse;
    align-items: stretch;
  }
}

.sidebar {
  order: 9999;
  width: 100%;
  min-height: 50vh;
  display: none;
}

@media (width >= 375px) {
  .sidebar {
    min-height: 30vh;
  }
}

@media (width >= 640px) {
  .sidebar {
    min-height: 30vh;
    display: block;
  }
}

@media (width >= 1024px) {
  .sidebar {
    order: 0;
    width: 55vw;
    min-height: 100vh;
  }
}

@media (width >= 1280px) {
  .sidebar {
    width: 60vw;
  }
}

.sidebar {
  background-image: -webkit-image-set(url("singapore-optical-shop-01.46cbeb43.webp") 1x, url("singapore-optical-shop-01.02ec9aa8.webp") 2x);
  background-image: image-set("singapore-optical-shop-01.46cbeb43.webp" 1x, "singapore-optical-shop-01.02ec9aa8.webp" 2x);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (width >= 640px) {
  .sidebar {
    background-image: -webkit-image-set(url("singapore-optical-shop-01.94181f8a.webp") 1x, url("singapore-optical-shop-01.b21331e0.webp") 2x);
    background-image: image-set("singapore-optical-shop-01.94181f8a.webp" 1x, "singapore-optical-shop-01.b21331e0.webp" 2x);
  }
}

@media (width >= 1024px) {
  .sidebar {
    background-image: -webkit-image-set(url("singapore-optical-shop-01.7468c426.webp") 1x, url("singapore-optical-shop-01.094d5bcb.webp") 2x);
    background-image: image-set("singapore-optical-shop-01.7468c426.webp" 1x, "singapore-optical-shop-01.094d5bcb.webp" 2x);
  }
}

@media (width >= 1536px) {
  .sidebar {
    background-image: -webkit-image-set(url("singapore-optical-shop-01.c1f6c62f.webp") 1x, url("singapore-optical-shop-01.47921da7.webp") 2x);
    background-image: image-set("singapore-optical-shop-01.c1f6c62f.webp" 1x, "singapore-optical-shop-01.47921da7.webp" 2x);
  }
}

.content {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.content > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.content {
  --tw-bg-opacity: 1;
  background-color: rgb(92 31 31 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  padding: 1.5rem;
}

@media (width >= 375px) {
  .content > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .content {
    padding: 2rem;
  }
}

@media (width >= 640px) {
  .content {
    min-height: 100vh;
    padding: 2.5rem;
  }
}

@media (width >= 1024px) {
  .content {
    width: 45vw;
    min-height: 100vh;
    padding: 3rem;
  }
}

@media (width >= 1280px) {
  .content {
    width: 40vw;
  }
}

.button {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  width: 100%;
  height: 44px;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  padding: .5rem 1rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.button:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (width >= 640px) {
  .button {
    width: auto;
    height: 48px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

@media (width >= 1024px) {
  .button {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.button-container > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

@media (width >= 640px) {
  .button-container > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
}

.button-grid {
  flex-direction: column;
  gap: .75rem;
  display: flex;
}

@media (width >= 640px) {
  .button-grid {
    flex-flow: wrap;
    gap: 1rem;
  }
}

.button-text {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  font-size: clamp(.875rem, .8rem + .375vw, 1rem);
  font-weight: 400;
  line-height: 1.5;
}

@media (width >= 640px) {
  .button-text {
    font-size: clamp(1rem, .9375rem + .5vw, 1.125rem);
    line-height: 1.6;
  }
}

.button-icon {
  width: 1.5rem;
  height: 1.5rem;
}

@media (width >= 640px) {
  .button-icon {
    width: 1.75rem;
    height: 1.75rem;
  }
}

@media (width >= 1024px) {
  .button-icon {
    width: 2rem;
    height: 2rem;
  }
}

.opening-hours {
  width: 100%;
  margin-top: 1.5rem;
}

.opening-hours > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

@media (width >= 375px) {
  .opening-hours {
    margin-top: 2rem;
  }

  .opening-hours > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
  }
}

.opening-hours-content {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: clamp(.875rem, .8rem + .375vw, 1rem);
  line-height: 1.5;
}

@media (width >= 375px) {
  .opening-hours-content {
    font-size: clamp(1rem, .9375rem + .5vw, 1.125rem);
    line-height: 1.6;
  }
}

.opening-hours-title {
  font-weight: 700;
}

.modal {
  z-index: 50;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
  --tw-bg-opacity: .5;
  display: none;
  position: fixed;
  inset: 0;
}

.modal.is-open {
  justify-content: center;
  align-items: center;
  display: flex;
}

.modal-content {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  width: 100%;
  height: 100vh;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  margin-left: 0;
  margin-right: 0;
  padding: 1rem;
  position: relative;
}

@media (width >= 375px) {
  .modal-content {
    padding: 1.5rem;
  }
}

@media (width >= 640px) {
  .modal-content {
    max-width: 85vw;
    height: auto;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 2rem;
  }
}

@media (width >= 1024px) {
  .modal-content {
    max-width: 56rem;
    padding: 3rem;
  }
}

.modal-close {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  padding: .5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: .5rem;
  right: .5rem;
}

.modal-close:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

@media (width >= 640px) {
  .modal-close {
    top: 1rem;
    right: 1rem;
  }
}

.modal-close svg {
  width: 1.5rem;
  height: 1.5rem;
}

.modal-map {
  width: 100%;
  height: calc(100vh - 8rem);
  margin-top: 2rem;
}

@media (width >= 640px) {
  .modal-map {
    height: 60vh;
    margin-top: 1rem;
  }
}

@media (width >= 1024px) {
  .modal-map {
    height: 70vh;
  }
}

.modal-map-container {
  width: 100%;
  padding-top: 75%;
  position: relative;
}

@media (width >= 1024px) {
  .modal-map-container {
    padding-top: 66.67%;
  }
}

.modal-map {
  position: absolute;
  inset: 0;
}

@media (width >= 768px) {
  .modal-map {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.modal-map {
  border: 0;
}
/*# sourceMappingURL=index.3b174949.css.map */
