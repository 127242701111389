@font-face {
  font-family: 'Roboto';
  src:
    local('Roboto Light'),
    local('Roboto-Light'),
    url('../fonts/Roboto-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src:
    local('Roboto Regular'),
    local('Roboto-Regular'),
    url('../fonts/Roboto-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter Regular'),
    local('Inter-Regular'),
    url('../fonts/Inter-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter Medium'),
    local('Inter-Medium'),
    url('../fonts/Inter-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter SemiBold'),
    local('Inter-SemiBold'),
    url('../fonts/Inter-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter Bold'),
    local('Inter-Bold'),
    url('../fonts/Inter-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
