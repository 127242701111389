@use 'fonts';
@tailwind base;
@tailwind components;
@tailwind utilities;

// Custom styles
body {
  @apply antialiased bg-gray-50 overflow-x-hidden;
}

// Layout
.main-section {
  @apply mx-auto flex flex-col xs:items-stretch sm:items-center lg:flex-row-reverse lg:items-stretch justify-between;
}

// Sidebar
.sidebar {
  @apply hidden sm:block
         min-h-[50vh] xs:min-h-[30vh] sm:min-h-[30vh] lg:min-h-screen 
         w-full lg:w-[55vw] xl:w-[60vw]
         order-last lg:order-none;
  background: {
    image: image-set(
      url('~/src/images/singapore-optical-shop-01.jpg?as=webp&width=375&quality=90') 1x,
      url('~/src/images/singapore-optical-shop-01.jpg?as=webp&width=750&quality=90') 2x
    );
    size: cover;
    position: center;
    repeat: no-repeat;
  }

  @screen sm {
    background-image: image-set(
      url('~/src/images/singapore-optical-shop-01.jpg?as=webp&width=640&quality=90') 1x,
      url('~/src/images/singapore-optical-shop-01.jpg?as=webp&width=1280&quality=90') 2x
    );
  }

  @screen lg {
    background-image: image-set(
      url('~/src/images/singapore-optical-shop-01.jpg?as=webp&width=1024&quality=90') 1x,
      url('~/src/images/singapore-optical-shop-01.jpg?as=webp&width=2048&quality=90') 2x
    );
  }

  @screen 2xl {
    background-image: image-set(
      url('~/src/images/singapore-optical-shop-01.jpg?as=webp&width=1536&quality=90') 1x,
      url('~/src/images/singapore-optical-shop-01.jpg?as=webp&width=3072&quality=90') 2x
    );
  }
}

// Content
.content {
  @apply min-h-screen sm:min-h-[100vh] lg:min-h-screen 
         w-full lg:w-[45vw] xl:w-[40vw] 
         space-y-6 xs:space-y-8 
         bg-brand-red text-white 
         p-6 xs:p-8 sm:p-10 lg:p-12 
         flex flex-col justify-center;
}

// Buttons and Links
.button {
  &-container {
    @apply space-y-3 sm:space-y-4;
  }

  &-grid {
    @apply flex flex-col sm:flex-row sm:flex-wrap gap-3 sm:gap-4;
  }

  @apply h-[44px] sm:h-[48px] 
         w-full sm:w-auto
         inline-flex items-center justify-center 
         gap-2 
         px-4 sm:px-5 lg:px-6 
         py-2 
         bg-white rounded-md 
         shadow-sm hover:shadow-md 
         transition-all;

  &-text {
    @apply text-gray-600 font-normal text-fluid-sm sm:text-fluid-base;
  }

  &-icon {
    @apply w-6 h-6 sm:w-7 sm:h-7 lg:w-8 lg:h-8;
  }
}

// Opening Hours
.opening-hours {
  @apply mt-6 xs:mt-8 space-y-1 xs:space-y-2 w-full;

  &-content {
    @apply text-white text-fluid-sm xs:text-fluid-base;
  }

  &-title {
    @apply font-bold;
  }
}

// Modal styles
.modal {
  @apply fixed inset-0 bg-black bg-opacity-50 z-50;
  display: none;

  &.is-open {
    @apply flex items-center justify-center;
  }

  &-content {
    @apply bg-white rounded-lg shadow-xl relative 
           w-full h-screen sm:h-auto
           sm:max-w-[85vw] lg:max-w-4xl 
           mx-0 sm:mx-4
           p-4 xs:p-6 sm:p-8 lg:p-12;
  }

  &-close {
    @apply absolute right-2 top-2 sm:right-4 sm:top-4
           p-2
           text-gray-500 hover:text-gray-700
           transition-colors;

    svg {
      @apply w-6 h-6;
    }
  }

  &-map {
    @apply w-full h-[calc(100vh-8rem)] sm:h-[60vh] lg:h-[70vh]
           mt-8 sm:mt-4;
  }
}

// Modal styles
.modal-map-container {
  @apply relative w-full;
  padding-top: 75%;
  @screen lg {
    padding-top: 66.67%; // 3:2 aspect ratio for larger screens
  }
}

.modal-map {
  @apply absolute inset-0 md:top-0 md:left-0 md:w-full md:h-full;
  border: 0;
}
